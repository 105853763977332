import React, { useState, useEffect,useContext } from "react";
import {Routes as Switch, Route, Navigate, useNavigate} from "react-router-dom";
import Main from "./Main";
import Resolved from "./InspectionFlags/ResolvedFlags";
import Groups from "./RemoteScan/Groups";
import Machine from "./RemoteScan/Machine";
import Reason from "./RemoteScan/Reason";
import Final from "./RemoteScan/FinalOption";
import Inspection from "./InspectionFlags/InspectionFlags";
import GroupOption from "./InspectionFlags/Options/GroupOption";
import MachineOption from "./InspectionFlags/Options/MachineOption";
import WorkerOption from "./InspectionFlags/Options/WorkerOption";
import Result from "./InspectionFlags/Options/Result";
import Availability from "./Availability/Availability";
import Log from "./Log/Log";
import Options from "./Log/Options";
import ManageAvailability from "./Availability/ManageAvailability";
import Menu from "./Burger";
import { MobileView } from "react-device-detect";
import { Navs, NavItems, QrCodeScannerIconWrapper } from "./NavbarStyles";
import { GlobalContext } from "../../context/GlobalContext";
import {ReactComponent as QrCodeScannerIcon} from "../../assets/icons/qr-code-scan-icon.svg"
import {RemoteScanSubmenu} from "./RemoteScan/RemoteScanSubmenu";
import {ScanQrCode} from "./RemoteScan/ScanQrCode";

function Nav() {
  const navigate = useNavigate();
  // const [isNavActive, setisNavActive] = useState(false);
  const { isNavActive, setisNavActive, menuClicked, setMenuClicked} =
    useContext(GlobalContext);
  const [isButtonVisible, setButtonVisibility] = useState(true);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setButtonVisibility(true);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  const handleClick = () => {
    setisNavActive((current) => !current);
  };

  return (
    <>
      <header className={"navbar"}>
        <div className={"navbar-content"}>
          <Navs>
            <img
                className="logoContainer top"
                alt="logo"
                src={require("../../assets/images/logo.png")}
            />
            <QrCodeScannerIconWrapper>
              <QrCodeScannerIcon
                  onClick={() => {
                    setisNavActive(true);
                    setMenuClicked(true);
                    navigate("/main/remote/scanQrCode");
                  }}>
              </QrCodeScannerIcon>
            </QrCodeScannerIconWrapper>
          </Navs>

        </div>
        {(isButtonVisible || MobileView) && <Menu handleClick={handleClick} />}
        <NavItems className={isNavActive ? "nav-show" : ""}>
          <Switch>
            <Route path="/main" element={<Main/>}/>
            <Route path="/main/resolved" element={<Resolved/>}/>
            <Route path="/main/inspection" element={<Inspection/>}/>
            <Route path="/main/setflag/group" element={<GroupOption/>}/>
            <Route path="/main/setflag/machine" element={<MachineOption/>}/>
            <Route path="/main/setflag/worker" element={<WorkerOption/>}/>
            <Route path="/main/setflag/result" element={<Result/>}/>
            <Route path="/main/remote" element={<RemoteScanSubmenu/>}/>
            <Route path="/main/remote/group" element={<Groups/>}/>
            <Route path="/main/remote/machine" element={<Machine/>}/>
            <Route path="/main/remote/scanQrCode/:machineId?" element={<ScanQrCode/>}/>
            <Route path="/main/remote/reason" element={<Reason onReasonClick={undefined}/>}/>
            <Route path="/main/remote/final" element={<Final onFinalClick={undefined} machine={undefined} includeBackButton={true}/>}/>
            <Route path="/main/availability" element={<Availability/>}/>

            <Route
                path="/main/availability/manage"
                element={<ManageAvailability/>}
            />
            <Route path="/main/log" element={<Log/>}/>
            <Route path="/main/log/options" element={<Options/>}/>

            <Route path="*" element={<Navigate to="/main"/>}/>
          </Switch>
        </NavItems>
      </header>
    </>
  );
}

export default Nav;
